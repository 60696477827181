// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS
function topFunc() {
  var firstText = document.getElementById('firstText');
  var secoundText = document.getElementById('secoundText');
  var sliderWrap = document.getElementById('sliderWrap');
  var headerWrap = document.getElementById('headerWrap');

  sliderWrap.classList.add('show');
  firstText.classList.add('show');
  secoundText.classList.add('show');

  var swiper = new Swiper('#galleryTop', {
    effect: 'fade',
    speed: 5000,
    autoplay: {
      delay: 5000
    },
    loop: true
  });
  setTimeout(function(){
    headerWrap.classList.add('show');
  }, 1200);
 
 
}

function subFunc() {
  var headerWrap = document.getElementById('headerWrap');
  headerWrap.classList.add('show');
  if ($('#sekouGallery').length) {
    $('#sekouGallery').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      titleSrc: 'title',
      gallery: { enabled: true }
      // other options
    });
  }
}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  $('.sub-page .head-line').addClass('show');
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log('script.js');

  var observer = lozad('.lozad', {
    loaded: function(el) {
      // Custom implementation on a loaded element
      // console.log(el);
      el.classList.add('loaded');
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($('#topPage').length) {
    topFunc();
  } else {
    subFunc();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $('#a.scroll').click(function() {
    $('html,body').animate(
      { scrollTop: $($(this).attr('href')).offset().top },
      'slow',
      'swing'
    );
    return false;
  });
  // MENU
  $('#menu-trigger').click(function() {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });

  // PC or SPサンプル
  if (mq('sp')) {
    console.log('Mobile');
  } else {
    console.log('Desktop');
  }
});

// SCROLL AND TOCH START ANIMATION
// $(window).on('touchstart scroll', function() {
//   var myClass = $(
//     '.head-line, #topPage .bussiness-list li, #topPage .vision-list li, #topPage .about-list li'
//   );
//   var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
//   var windowHeight = document.documentElement.clientHeight;
//   for (var i = 0; i < myClass.length; i++) {
//     var rect = myClass[i].getBoundingClientRect();
//     var imgPos = rect.top + scrollTop + 300;

//     if (scrollTop > imgPos - windowHeight) {
//       myClass[i].classList.add('show');
//     } else if (scrollTop < imgPos + windowHeight) {
//       // myClass[i].classList.remove('show');
//     }
//   }
//   if (scrollTop > 150) {
//     document.getElementById('headerWrap').classList.add('fixed');
//     document.getElementById('pageTop').classList.add('show');
//   } else if (scrollTop < 150) {
//     document.getElementById('headerWrap').classList.remove('fixed');
//     document.getElementById('pageTop').classList.remove('show');
//   }
// });
